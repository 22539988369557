<template>
  <div class="d-flex flex-column justify-content-between">
    <!-- SECTION - MÃ ĐẶT CHỖ, CONTACT -->
    <b-card
      body-class="py-75 pb-md-1 px-75 px-md-2"
      class="border-info-2 shadow-card mb-50"
    >
      <div
        class="d-flex flex-column flex-md-row gap-1"
        :class="isMobileView ? 'flex-column' : ''"
      >
        <div :class="`${isMobileView ? '' : 'flex-1'}`">
          <!-- ANCHOR - Mã đặt chỗ các link liên kết-->
          <div class="d-flex align-items-center mb-25 flex-wrap">
            <div
              class="d-flex-center border-airline px-1 py-75 bg-light-info rounded-8px my-25"
              style="width: fit-content;"
            >
              <span class="font-weight-bolder d-flex-center justify-content-start divider-after">
                <!-- {{ $t('reservation.bookingCode') }}: -->
                <b
                  class="text-airline font-large-1 text-nowrap"
                  :class="`${isMobileView ? 'font-medium-3' : 'font-large-1'}`"
                >
                  {{ reservationsData.bookingCode || reservationsData.pnr }}
                </b>
                <BButton
                  v-b-tooltip.hover.window.top="$t('copy')"
                  variant="flat-primary"
                  class="p-50 rounded-circle ml-25"
                  :disabled="disabledCopy"
                  @click="copyPnr(reservationsData?.bookingCode || reservationsData?.pnr)"
                >
                  <IAmIcon
                    icon="copy"
                    size="18"
                    color="#2FA4E5"
                  />
                </BButton>
              </span>
              <IAmLogoAirline
                right
                :airline="reservationsData.airline || reservationsData.airlines.split(' | ')[0] || reservationsData.source"
                size="sm"
              />
            </div>

            <div v-if="reservationsData?.roundtripBooking || !isEmpty(reservationsData?.multiCityBookings) || !isEmpty(reservationsData?.crossReferenceDetails)">
              <b-img
                class="bg-light-info rounded-circle p-50 my-25"
                :class="isMobileView ? `mx-25` : `mx-1`"
                src="@icons/link_alt.svg"
                :width="`30`"
              />
            </div>

            <!-- link -->
            <div class="d-flex flex-row gap-1 flex-wrap">
              <!-- ANCHOR - roundtripBooking : vé khứ hồi -->
              <b-button
                v-if="reservationsData?.roundtripBooking"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="p-25 p-md-50 d-flex align-items-end border-airline my-25"
                variant="flat-primary"
                @click="openLinkedBookingHandle(reservationsData.roundtripBooking.id) "
              >
                <div class="d-flex flex-column align-items-center mr-50">
                  <IAmLogoAirline
                    right
                    :airline="reservationsData.roundtripBooking.airlines
                      ? reservationsData.roundtripBooking.airlines.split(' | ')[0]
                      : reservationsData.roundtripBooking.source"
                    size="sm"
                  />
                  <i
                    class="text-nowrap"
                    :class="isMobileView? 'font-small-1 ': 'font-small-3 '"
                  >
                    ({{ $t('reservation.roundtripTicket') }})
                  </i>
                </div>

                <div
                  class="text-airline"
                  :class="isMobileView ? `font-small-4` : `font-medium-4`"
                >
                  <div class="font-weight-bolder">
                    {{ reservationsData.roundtripBooking.bookingCode }}
                  </div>

                  <div
                    v-if="!isEmpty(reservationsData.roundtripBooking.itineraries)"
                    class="mt-75"
                    :class="isMobileView ? 'font-small-3 d-flex px-25' : 'font-medium-1'"
                  >
                    <span :class="isMobileView ? `font-small-3` : `font-medium-1`">
                      {{ reservationsData.roundtripBooking.itineraries[0][0].departure.iataCode }}
                    </span>
                    <b-img
                      v-if="!isMobileView"
                      src="@icons/swap-horizontal.svg"
                      :class="isMobileView ? `mx-25` : `mx-1`"
                      :width="isMobileView ? `13` : `16`"
                    />
                    <span v-else> - </span>
                    <span :class="isMobileView ? `font-small-2` : `font-medium-1`">
                      {{ reservationsData.roundtripBooking.itineraries[0][reservationsData.roundtripBooking.itineraries[0].length - 1].arrival.iataCode }}
                    </span>
                  </div>
                  <div
                    v-else
                    class="font-medium-1 mt-75"
                  >
                    <b-badge
                      v-if="reservationsData"
                      :variant="`light-${resolveBookingStatusVariant( reservationsData.roundtripBooking.status || reservationsData.roundtripBooking.bookingStatus )}`"
                      class="badge-glow round"
                    >
                      <small :class="`font-weight-bolder text-${resolveBookingStatusVariant( reservationsData.roundtripBooking.status || reservationsData.roundtripBooking.bookingStatus )}`">
                        {{
                          reservationsData.roundtripBooking.status || reservationsData.roundtripBooking.bookingStatus
                        }}
                      </small>
                    </b-badge>
                  </div>
                </div>
              </b-button>

              <!-- ANCHOR - multiCityBookings -->
              <div
                v-if="!isEmpty(reservationsData?.multiCityBookings)"
                class="d-flex flex-row gap-1 flex-wrap"
              >
                <b-button
                  v-for="(linkedReservationsData) in reservationsData.multiCityBookings"
                  :key="linkedReservationsData.id"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="p-25 p-md-50 d-flex align-items-center border-airline my-25"
                  variant="flat-primary"
                  @click="openLinkedBookingHandle(linkedReservationsData.id)"
                >
                  <div class="d-flex flex-column align-items-center mr-50">
                    <IAmLogoAirline
                      right
                      :airline="resolveAirlineImg(linkedReservationsData)"
                      size="sm"
                    />
                    <i
                      class="text-nowrap"
                      :class="isMobileView? 'font-small-1 ': 'font-small-3 '"
                    >
                      ({{ linkedReservationsData.isBookingGroup ? $t('reservation.groupTicket') : $t('reservation.splitIntineraryTicket') }})
                    </i>
                  </div>

                  <div class="text-airline d-flex flex-column align-items-center justify-content-end">
                    <div class="font-weight-bolder">
                      {{ linkedReservationsData.bookingCode }}
                    </div>

                    <div
                      v-if="!isEmpty(linkedReservationsData.itineraries)"
                      class="font-medium-1 mt-50 text-nowrap text-black"
                    >
                      <span> {{ linkedReservationsData.itineraries[0][0].departure.iataCode }} </span>
                      <b-img
                        v-if="!isMobileView"
                        src="@icons/swap-horizontal.svg"
                        class="mx-1"
                        width="16"
                      />
                      <span v-else> - </span>
                      <span>{{ linkedReservationsData.itineraries[linkedReservationsData.itineraries.length - 1][linkedReservationsData.itineraries[linkedReservationsData.itineraries.length - 1].length - 1].arrival.iataCode }}</span>
                    </div>

                    <div
                      v-else
                      class="font-medium-1 mt-50 text-nowrap"
                    >
                      <b-badge
                        v-if="reservationsData"
                        :variant="`light-${resolveBookingStatusVariant(linkedReservationsData.status || linkedReservationsData.bookingStatus)}`"
                        class="badge-glow round"
                      >
                        <small :class="`font-weight-bolder text-${resolveBookingStatusVariant(linkedReservationsData.status || linkedReservationsData.bookingStatus)}`">
                          {{ linkedReservationsData.status || linkedReservationsData.bookingStatus }}
                        </small>
                      </b-badge>
                    </div>
                  </div>
                </b-button>
              </div>

              <!-- ANCHOR - crossReferenceDetails -->
              <div
                v-if="!isEmpty(reservationsData?.crossReferenceDetails)"
                class="mb-0 d-flex flex-row gap-1"
              >
                <b-button
                  v-for="(booking, indexCrossReferenceDetails) of reservationsData.crossReferenceDetails"
                  :key="indexCrossReferenceDetails"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-primary"
                  class="p-25 p-md-50 d-flex flex-column align-items-center border-airline my-25"
                  @click="openCrossReferenceHandle(reservationsData, booking)"
                >
                  <div class="d-flex-center w-100">
                    <IAmLogoAirline
                      right
                      :airline="resolveAirlineImg(reservationsData)"
                      size="sm"
                      custom-class="ml-50"
                    />
                    <div class="text-airline font-weight-bolder mr-50">
                      {{ booking.pnrNumber }}
                    </div>
                  </div>
                  <i
                    class="text-nowrap"
                    :class="isMobileView? 'font-small-1': 'font-small-3'"
                  >
                    ({{ $t('reservation.splitPassengerTicket') }})
                  </i>
                </b-button>
              </div>
            </div>
          </div>

          <!-- ANCHOR - STATUS -->
          <div class="mb-50 d-flex align-items-center">
            <span class="font-weight-bolder mr-50">{{ $t('reservation.status') }}:
            </span>
            <b-badge
              :variant="`light-${resolveBookingStatusVariant(reservationsData.status === 'PARTIALLY_PAID' ? 'PAID' : (reservationsData.status || reservationsData.bookingStatus))}`"
              class="badge-glow rounded-lg px-75 py-50 mr-50"
            >
              <b :class="`${isMobileView ? 'font-medium-2' : 'font-medium-3'} fw-800 text-uppercase text-${resolveBookingStatusVariant(reservationsData.status === 'PARTIALLY_PAID' ? 'PAID' : (reservationsData.status || reservationsData.bookingStatus))}`">
                {{ $t(`reservation.${reservationsData.status === 'PARTIALLY_PAID' ? 'PAID' : (reservationsData.status || reservationsData.bookingStatus)}`) }}
              </b>
            </b-badge>

            <b-badge
              v-if="reservationsData.status === 'PARTIALLY_PAID'"
              :variant="`light-${resolveBookingStatusVariant(reservationsData.status || reservationsData.bookingStatus)}`"
              class="badge-glow rounded-lg px-75 py-50 mr-50"
            >
              <b :class="`${isMobileView ? 'font-medium-2' : 'font-medium-3'} fw-800 text-uppercase text-${resolveBookingStatusVariant(reservationsData.status === 'PARTIALLY_PAID' ? 'PARTIALLY_PAID_ONLY' : (reservationsData.status || reservationsData.bookingStatus))}`">
                {{ $t(`reservation.${reservationsData.status === 'PARTIALLY_PAID' ? 'PARTIALLY_PAID_ONLY' : (reservationsData.status || reservationsData.bookingStatus)}`) }}
              </b>
            </b-badge>

            <b-button
              v-if="['HOLD'].includes(reservationsData.status) && reservationsData.timeHold"
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              v-b-tooltip.hover.v-primary.window
              :title="reservationsData.isPaymentReminder ? 'Đang BẬT thông báo nhắc xuất vé' : 'Đang TẮT thông báo nhắc xuất vé'"
              :variant="`flat-${reservationsData.isPaymentReminder ? 'success' : 'danger'}`"
              class="btn-icon rounded-circle"
              @click="handleTogglePaymentReminder(reservationsData)"
            >
              <feather-icon
                v-if="reservationsData.isPaymentReminder"
                icon="BellIcon"
              />
              <feather-icon
                v-else
                icon="BellOffIcon"
              />
            </b-button>
          </div>

          <!-- ANCHOR số tiền bảo lưu định danh VJ `refundAmountIdentification` -->
          <div
            v-if="reservationsData.source === 'VJ' && reservationsData.refundAmountIdentification"
            class="mb-50 font-weight-bolder"
          >
            <span class="text-warning">
              {{ $t('reservation.amountRefunded') }}:
            </span>

            <span class="fw-700 font-medium-3 text-warning">
              -{{ formatCurrency(reservationsData.refundAmountIdentification) }}
            </span>
          </div>

          <div
            v-if="reservationsData.timeHold && !reservationsData.status.includes('PAID')"
            class="mb-50 font-weight-bolder"
          >
            <span class="">
              {{ $t('reservation.timeHold') }}:
            </span>
            <span class="text-danger">
              {{ convertISODateTime(reservationsData.timeHold).dateTime }}
            </span>
          </div>

          <!-- ANCHOR: `lastDateToPurchase` thời hạn xuất vé -->
          <div
            v-if="lastDateToPurchase && !reservationsData.status.includes('PAID')"
            class="mb-50 font-weight-bolder"
          >
            <span class="">
              {{ $t('reservation.priceDetails.titleTable.lastDateToPurchase') }}:
            </span>
            <span class="text-danger">
              {{ lastDateToPurchase }}
            </span>
          </div>

          <!-- ANCHOR: `expirationTime` Thời hạn giữ giá -->
          <div
            v-if="expirationTime && !reservationsData.status.includes('PAID')"
            class="mb-50 font-weight-bolder"
          >
            <span class="">
              {{ $t('reservation.priceDetails.titleTable.expirationTime') }}:
            </span>
            <span class="text-danger">
              {{ expirationTime }}
            </span>
          </div>

          <div
            v-if="(reservationsData.autoIssueTime && reservationsData.status === 'HOLD')"
            class="mb-50"
          >
            <span class="font-weight-bolder text-warning">
              {{ $t('reservation.autoIssueTime') }}:
            </span>
            <span class="font-weight-bolder text-warning">
              {{ convertISODateTime(reservationsData.autoIssueTime).dateTime }}
            </span>
          </div>

          <div
            v-if="reservationsData.dateCreate"
            class="mb-50"
          >
            <span class="font-weight-bolder">
              {{ $t('reservation.createdAt') }}:
            </span>
            <span class="font-weight-bolder">
              {{ convertISODateTime(reservationsData.dateCreate).dateTime }}
            </span>
          </div>

          <!-- ANCHOR Thông tin totalToBePaid, totalPaid -->
          <div
            v-if="reservationsData.totalAmountToBePaid && reservationsData.status !== 'CANCEL'"
            class="sticky-total-price"
          >
            <IAmCollapseAlert :show="isShowTotalPricePaid">
              <div class="d-flex-center text-nowrap">
                <span class="font-weight-bolder text-dark">
                  {{ $t('reservation.needPayment') }}:
                </span>
                <span
                  class="font-medium-5 fw-700 ml-50"
                  style="color: #FF0500"
                >
                  {{ formatCurrency(reservationsData.totalAmountToBePaid) || 0 }}
                </span>
              </div>
              <!-- Ẩn đã thanh toán - a cong -->
              <!-- <div>
                  <span class="font-weight-bolder text-dark">
                    Đã thanh toán:
                  </span>
                  <span class="font-medium-3 font-weight-bolder text-success">
                    {{ formatCurrency(reservationsData.totalAmountPaid) || 0 }}
                  </span>
                </div> -->
            </IAmCollapseAlert>
          </div>

          <b-alert
            v-if="reservationsData.header && reservationsData.header.includes('PNR MODIFIED - CHECK ITINERARY')"
            class="mb-50 px-1 py-50 d-flex-center "
            variant="danger"
            show
          >
            <feather-icon
              icon="AlertOctagonIcon"
              size="20"
              class="mr-75"
            />
            <div>
              <div class="font-weight-bolder">
                PNR MODIFIED - CHECK ITINERARY
              </div>
              <div>Booking đã bị thay đổi. Vui lòng kiểm tra lại hành trình.</div>
            </div>
          </b-alert>
        </div>

        <!-- ANCHOR Thông tin liên hệ -->
        <div
          v-if="reservationsData.contact && reservationsData.contact.paxInfo && isShowContactInfo"
          class="d-flex flex-column gap-2"
        >
          <!-- TITLE -->
          <div class="fw-800 d-flex align-items-center justify-content-between w-100">
            <span>
              {{ $t('reservation.contactInfo') }}
            </span>
            <b-button
              v-if="!['VN1A_MT', 'VN1A'].includes(reservationsData.source) && !isHideFunctionButton && !hideFeature"
              variant="flat-primary"
              class="p-50 ml-1 ml-md-0"
              :disabled="isBookingOutSystem || !canIssueLimit"
              @click="openEditContactModalHandle"
            >
              {{ $t('reservation.detail') }}
            </b-button>
          </div>
          <!-- PHONE -->
          <div>
            <span>{{ $t('reservation.phoneNumber') }}: </span>
            <span
              v-if="reservationsData.contact.paxInfo.phone.length"
              class="font-weight-bolder"
            >
              <span
                v-for="(phone, phoneIndex) in reservationsData.contact.paxInfo.phone"
                :key="phoneIndex"
              >
                <component :is="phoneIndex === 0 || isMobileView ? 'span' : 'div'">
                  <span v-if="phone.prefix">
                    ({{ phone.prefix }})
                  </span>
                  {{ phone.address ? phone.address : '-' }}{{ phoneIndex !== reservationsData.contact.paxInfo.phone.length - 1 ? ', ' : '' }}
                </component>
              </span>
            </span>
          </div>
          <!-- EMAIL -->
          <div>
            <span>Email:</span>
            <span
              v-if="!isEmpty(reservationsData.contact.paxInfo.email)"
              class="font-weight-bolder"
            >
              <span
                v-for="(email, emailIndex) in reservationsData.contact.paxInfo.email"
                :key="emailIndex"
              >
                <component :is="emailIndex === 0 || isMobileView ? 'span' : 'div'">
                  {{ email.address ? email.address : '-' }}{{ emailIndex !== reservationsData.contact.paxInfo.email.length - 1 ? ', ' : '' }}
                </component>
              </span>
            </span>
          </div>
        </div>
      </div>
    </b-card>

    <div class="mt-0 mb-50 d-flex-center gap-1 justify-content-end">
      <b-button
        v-if="reservationsData.source.includes('VN1A') && !isHideFunctionButton && !hideFeature"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        size="sm"
        :disabled="isBookingOutSystem"
        @click="openSendQueuesModalHandle"
      >
        <feather-icon
          icon="SendIcon"
          class="mr-50"
        />
        {{ $t('reservation.sendQueues.title') }}
      </b-button>
      <b-button
        v-if="(['VN1A', 'VN1A_MT'].includes(reservationsData.source)
          || (['1A'].includes(reservationsData.source) && !NDC_1A_BOOKING))
          && !isHideFunctionButton && !hideFeature"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        size="sm"
        :disabled="isBookingOutSystem"
        @click="openRemarksModalHandle"
      >
        <feather-icon
          icon="TagIcon"
          class="mr-50"
        />
        {{ $t('reservation.remarks.title') }}
      </b-button>
      <b-button
        v-if="reservationsData.source.includes('VN1A') && !isHideFunctionButton && !hideFeature"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        size="sm"
        :disabled="isBookingOutSystem"
        @click="openOSIFlightModalHandle"
      >
        <feather-icon
          icon="BellIcon"
          class="mr-50"
        />
        {{ $t('reservation.osi.title') }}
      </b-button>
      <!-- <b-button
        v-if="!isHideFunctionButton && !hideFeature"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="warning"
        size="sm"
        :disabled="isBookingOutSystem"
        @click="openSpecialServicesModalHandle"
      >
        <feather-icon
          icon="ShoppingBagIcon"
          class="mr-50"
        />
        {{ $t('reservation.specialServices.title') }}
      </b-button> -->
    </div>
    <!-- !SECTION -->

    <!-- SECTION - THÔNG TIN CHUYẾN BAY -->
    <div>
      <AppCollapse
        type="border-info-2 card"
        class="mb-50"
      >
        <AppCollapseItem
          is-visible
          title
          class-header="card-header bg-blue-gradient p-0 m-50"
          class="border-info-2"
        >
          <template #header>
            <div class="d-flex-center justify-content-start gap-1">
              <b-img
                src="@icons/info-outline.svg"
                class="icon-card"
                width="50px"
              />
              <h6 class="text-airline font-medium-3 m-0 fw-700">
                {{ $t('reservation.flightsDetails.title') }}
              </h6>
            </div>
          </template>

          <CancelFlight />
          <div v-if="!reservationsData.itineraries.length && isEmpty(cancelFlightList)">
            <b-alert
              variant="warning"
              :show="true"
              class="p-1"
            >
              {{ $t('reservation.flightHasBeenCancelled') }}
            </b-alert>
          </div>

          <div v-else-if="reservationsData.itineraries.length">
            <div
              v-for="(flight, index) of (['1S','1S_CTRL', '1G'].includes(reservationsData.source) && reservationsData.arunks && reservationsData.arunks.length ? addArunksToItineraries(reservationsData) : reservationsData.itineraries)"
              :key="index"
            >
              <b-alert
                v-if="flight.segmentId"
                show
                variant="warning"
                class="p-1"
              >
                {{ $t('reservation.haveArunk') }}
              </b-alert>
              <b-table-lite
                v-else
                bordered
                class="mb-50 border-secondary rounded-8px"
                responsive
                :items="flight"
                :fields="['Flight']"
                thead-class="text-dark font-weight-bolder"
              >
                <template
                  v-for="column in ['Flight']"
                  #[`head(${column})`]="data"
                >
                  <span
                    :key="column.label"
                    class="text-dark text-nowrap"
                  >
                    <span v-if="indexFlightWithArunks">{{ `${$t(`reservation.${data.label}`)} #${indexFlightWithArunks[index]
                      || index + 1}` }}</span>
                    <span v-else>{{ `${$t(`reservation.${data.label}`)} #${index + 1}` }}</span>
                  </span>
                </template>

                <template #cell(Flight)="data">
                  <b-row>
                    <!-- ANCHOR logo -->
                    <b-col
                      cols="12"
                      lg="3"
                      :class="isMobileView ? 'mb-1' : 'pl-50 pr-0'"
                    >
                      <div class="d-flex-center h-100 gap-2">
                        <IAmLogoAirline
                          right
                          :airline="data.item.operating && data.item.operating.length === 2 && data.item.operating === 'BL' ? data.item.operating : data.item.airline"
                          size="md"
                        />

                        <div>
                          <h5 class="fw-700 font-medium-3">
                            {{ resolveAirlineFlightNumber(data.item.airline, data.item.flightNumber ) }}
                          </h5>
                          <h5 class="font-weight-bolder align-middle mt-50 mb-0 font-medium-2">
                            {{ getAirCraftByIata(data.item.airCraft) }}
                          </h5>
                        </div>
                      </div>
                    </b-col>

                    <!-- ANCHOR flight info -->
                    <b-col
                      cols="12"
                      lg="5"
                      :class="isMobileView ? 'px-50 mb-1' : 'px-0'"
                    >
                      <!-- NOTE for 1G flightLegInfoList -->
                      <div
                        v-if="data.item.flightLegInfoList && data.item.flightLegInfoList.length > 0"
                        class="d-flex-center flex-column h-100"
                      >
                        <div
                          v-for="(seg, segIndex) in data.item.flightLegInfoList"
                          :key="segIndex"
                          class="d-flex-center py-50"
                        >
                          <div class="d-flex-center flex-column">
                            <b class="text-warning fw-700 font-medium-3">
                              {{ seg.departure.IATACode }}
                            </b>
                            <!-- <small v-if="segIndex !== 0">(Chặng ẩn)</small> -->
                            <span
                              v-b-tooltip.hover.v-primary
                              class="fw-700 font-medium-3"
                              :title="`GMT ${seg.departure.timezone || seg.departure.timeZone}`"
                            >
                              {{ convertISODateTime( seg.departure.at, seg.departure.timezone || seg.departure.timeZone, ).time }}
                            </span>
                            <p class="mb-0">
                              {{ convertISODateTime( seg.departure.at, seg.departure.timezone || seg.departure.timeZone, ).date }}
                            </p>
                          </div>

                          <div class="d-flex flex-column align-items-center mx-1 my-auto">
                            <em class="mb-0">
                              {{ toHoursAndMinutes(seg.duration) }}
                            </em>
                            <div class="text-nowrap">
                              <b-img
                                src="@icons/line-flight.svg"
                                style="max-width: 180px"
                              />
                            </div>
                          </div>

                          <div class="d-flex flex-column align-items-center">
                            <div>
                              <b class="text-warning fw-700 font-medium-">
                                {{ seg.arrival.IATACode }}
                              </b>
                            <!-- <small v-if="segIndex !== data.item.flightLegInfoList.length - 1">(Chặng ẩn)</small> -->
                            </div>
                            <span
                              v-b-tooltip.hover.v-primary
                              :title="`GMT ${seg.arrival.timezone || seg.arrival.timeZone}`"
                              class="fw-700 font-medium-3"
                            >
                              {{ convertISODateTime( seg.arrival.at, seg.arrival.timezone || seg.arrival.timeZone, ).time }}
                            </span>
                            <p class="mb-0">
                              {{ convertISODateTime( seg.arrival.at, seg.arrival.timezone || seg.arrival.timeZone, ).date }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        v-else
                        class="d-flex-center h-100"
                        :class="isMobileView ? 'mb-75' : ''"
                      >
                        <div class="d-flex flex-column align-items-center">
                          <b class="text-warning fw-700 font-medium-3">
                            {{ data.item.departure.iataCode }}
                          </b>
                          <span
                            v-b-tooltip.hover.v-primary
                            :title="`GMT ${data.item.departure.timeZone || data.item.departure.timezone}`"
                            class="fw-700 font-medium-3"
                          >
                            {{ convertISODateTime( data.item.departure.at, data.item.departure.timeZone || data.item.departure.timezone, ).time }}
                          </span>
                          <p class="mb-0">
                            {{ convertISODateTime( data.item.departure.at, data.item.departure.timeZone || data.item.departure.timezone, ).date }}
                          </p>
                        </div>

                        <div class="d-flex flex-column align-items-center mx-1 my-auto">
                          <em class="mb-0">
                            {{ toHoursAndMinutes(data.item.duration) }}
                          </em>
                          <div class="text-nowrap">
                            <b-img
                              src="@icons/line-flight.svg"
                              style="max-width: 180px"
                            />
                          </div>
                        </div>

                        <div class="d-flex flex-column align-items-center">
                          <b class="text-warning fw-700 font-medium-3">
                            {{ data.item.arrival.iataCode }}
                          </b>
                          <span
                            v-b-tooltip.hover.v-primary
                            class="fw-700 font-medium-3"
                            :title="`GMT ${data.item.arrival.timeZone || data.item.arrival.timezone}`"
                          >
                            {{ convertISODateTime( data.item.arrival.at, data.item.arrival.timeZone || data.item.arrival.timezone, ).time }}
                          </span>
                          <p class="mb-0">
                            {{ convertISODateTime( data.item.arrival.at, data.item.arrival.timeZone || data.item.arrival.timezone, ).date }}
                          </p>
                        </div>
                      </div>
                    </b-col>

                    <!-- ANCHOR text info -->
                    <b-col
                      cols="12"
                      lg="4"
                      class="px-1 d-flex"
                    >
                      <FlightInfoCard
                        :flight-info="data.item"
                        :reservations-data="reservationsData"
                        :index="data.index"
                      />
                    </b-col>

                    <!-- ANCHOR passenger info for VU -->
                    <b-col
                      v-if="reservationsData.source === 'VU' && reservationsData?.paxLists.filter(p => p?.eticket?.some(et => et?.segmentId.includes(data.item?.segmentId)))?.length"
                      cols="12"
                    >
                      <div class="fw-700 text-warning">
                        {{ $t('reservation.passenger') }}:
                      </div>
                      <!-- <pre>{{ data.item }}</pre> -->
                      <div>
                        <div
                          v-for="(pax, paxIndex) in reservationsData.paxLists.filter(p => p.eticket.some(et => et.segmentId.includes(data.item.segmentId)))"
                          :key="paxIndex"
                        >
                          <div class="d-flex flex-column align-items-start mr-50">
                            <div :class="isMobileView ? 'font-medium-1' : 'font-medium-3'">
                              <span>{{ `${paxIndex + 1}. ` }}</span>
                              <code class="text-body font-italic bg-transparent pl-0 pr-25">
                                {{ `${pax.paxType ? `(${$t(`reservation.${pax.paxType}`)})` : ''}` }}
                              </code>
                              <b>{{ `${pax.lastName} / ${pax.firstName} ${pax.title || ''} ` }}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </template>
              </b-table-lite>
            </div>
          </div>
        </AppCollapseItem>
      </AppCollapse>
      <div
        v-if="!isHideFunctionButton && !hideFeature"
        class="w-100 d-flex-center justify-content-end mt-0 mb-50 gap-1 flex-wrap"
      >
        <!-- ANCHOR BUTTON: TÍNH GIÁ -->
        <b-button
          v-if="['VN1A', 'VN1A_MT'].includes(reservationsData.source) || (['1G'].includes(reservationsData.source) && reservationsData.extra.some(item => item.contentSource === 'GDS'))"
          variant="primary"
          class="px-75 d-flex-center gap-1"
          size="sm"
          :disabled="!reservationsData.itineraries.length || isBookingOutSystem || !canIssueLimit || (['1G', 'VN1A', 'VN1A_MT'].includes(reservationsData.source) && reservationsData.status.includes('PAID'))"
          @click="openRecalculatePriceModalHandle(reservationsData.source)"
        >
          <b-img
            src="@icons/reload.svg"
            width="16"
          />
          <span class="font-small-4">{{ $t('reservation.recalculatePriceFlight') }}</span>
        </b-button>

        <!-- ANCHOR BUTTON: THÊM NHANH CHẶNG BAY -->
        <!-- <b-button
          v-if="reservationsData.source.includes('1S')"
          variant="warning"
          class="px-75 d-flex-center gap-1"
          size="sm"
          :disabled="(!reservationsData.source.includes('1S') && reservationsData.source !== 'VJ') || !canAccess('booking.addFlight') || isBookingOutSystem || !canIssueLimit || reservationsData.status === 'CANCEL'"
          @click="openQuickAddFlightModalHandle"
        >
          <b-img
            src="@icons/increase.svg"
            width="16"
          />
          <span class="font-small-4">
            {{ $t('reservation.addQuickFlight') }}</span>
        </b-button> -->

        <!-- ANCHOR BUTTON: THÊM CHUYẾN BAY (VJ, VN1A) -->
        <b-button
          v-if="['VJ', 'VN1A', 'VN1A_MT'].includes(reservationsData.source)"
          variant="primary"
          class="px-75 d-flex-center gap-1"
          size="sm"
          :disabled="!canAccess('booking.addFlight') || isBookingOutSystem || !canIssueLimit"
          @click="openAddFlightModalHandle"
        >
          <b-img
            src="@icons/increase.svg"
            width="16"
          />
          <span
            v-if="['VN1A', 'VN1A_MT'].includes(reservationsData.source)"
            class="font-small-4"
          >
            {{ $t('reservation.addFlight/DS') }}
          </span>
          <span
            v-else
            class="font-small-4"
          >
            {{ $t('reservation.addFlight') }}
          </span>
        </b-button>

        <!-- ANCHOR BUTTON: XÁC NHẬN SC -->
        <b-button
          v-if="['QH', 'VN1A'].includes(reservationsData.source)"
          variant="primary"
          class="px-75 d-flex-center gap-1"
          size="sm"
          :disabled="isBookingOutSystem || !canIssueLimit"
          @click="handleConfirmSC(reservationsData.itineraries)"
        >
          <b-img
            src="@icons/checked.svg"
            width="16"
          />
          <span class="font-small-4"> {{ $t('reservation.confirmSC') }} </span>
        </b-button>

        <!-- ANCHOR BUTTON: XÁC NHẬN CHỖ -->
        <b-button
          v-if="reservationsData.source.includes('1S')"
          variant="primary"
          class="px-75 d-flex-center gap-1"
          size="sm"
          :disabled="isBookingOutSystem || !canIssueLimit"
          @click="openSegmentsHandleModal"
        >
          <b-img
            src="@icons/checked.svg"
            width="16"
          />
          <span class="font-small-4"> {{ $t('reservation.confirmSegments.title') }}</span>
        </b-button>

        <!-- ANCHOR BUTTON: HOÀN VÉ -->
        <b-button
          v-if="['VJ', 'QH'].includes(reservationsData.source)"
          variant="warning"
          class="px-75 d-flex-center gap-1"
          size="sm"
          :disabled="disableRefundFlight(reservationsData) || !canAccess('booking.cancelFlight') || isBookingOutSystem || !canIssueLimit"
          @click="openCancelFlightModalHandle(reservationsData, 'REFUND')"
        >
          <b-img
            src="@icons/clear.svg"
            width="16"
          />
          <span class="font-small-4">{{ reservationsData.source === 'VJ' ? $t('reservation.refundTicketVJ') : $t('reservation.refundTicket') }}</span>
        </b-button>

        <!-- ANCHOR BUTTON: ĐỔI SỐ VÉ [VN1A, VN1A_MT], Đổi chuyến bay [VJ, QH] -->
        <b-button
          v-if="['VN1A', 'VN1A_MT', 'VJ', 'QH'].includes(reservationsData.source)"
          variant="primary"
          class="px-75 d-flex-center gap-1"
          size="sm"
          :disabled="disableChangeFlight(reservationsData) || !canAccess('booking.changeFlight') || isBookingOutSystem || !canIssueLimit"
          @click="openChangeFlightHandle(reservationsData)"
        >
          <feather-icon
            icon="RefreshCcwIcon"
            class="mr-50"
          />
          <span
            v-if="['VN1A', 'VN1A_MT', '1A'].includes(reservationsData.source)"
            class="font-small-4"
          > {{ $t('reservation.changeItinerary') }} </span>
          <span
            v-else
            class="font-small-4"
          > {{ $t('reservation.changeFlights') }} </span>
        </b-button>

        <!-- ANCHOR BUTTON: HUỶ CHUYẾN BAY -->
        <b-button
          v-if="!['VU', 'AK', 'TH', 'F1', '1A'].includes(reservationsData.source) || (['1A'].includes(reservationsData.source) && !NDC_1A_BOOKING)"
          variant="danger"
          class="px-75 d-flex-center gap-1"
          size="sm"
          :disabled="disableCancelFlight(reservationsData) || !canAccess('booking.cancelFlight') || isBookingOutSystem"
          @click="openCancelFlightModalHandle(reservationsData)"
        >
          <b-img
            src="@icons/clear.svg"
            width="16"
          />
          <span class="font-small-4">{{ $t('reservation.cancelFlight') }}</span>
        </b-button>
      </div>
    </div>
    <!-- !SECTION -->

    <!-- ANCHOR - CHI TIẾT HÀNH KHÁCH -->
    <PassengerDetails
      :reservations-data="reservationsData"
      :is-booking-out-system="isBookingOutSystem"
      :is-hide-function-button="isHideFunctionButton"
      :can-issue-limit="canIssueLimit"
    />

    <!-- ANCHOR Chi tiết giá mới 06/2024 -->
    <PriceDetails
      :reservations-data="reservationsData"
      :is-booking-out-system="isBookingOutSystem"
      :is-hide-function-button="isHideFunctionButton"
      :can-issue-limit="canIssueLimit"
      :is-locked-modify="isLockedModify"
    />

    <!-- ANCHOR - DANH SÁCH SỐ VÉ -->
    <ETicketDetails
      v-if="['VN1A_MT', 'VN1A', '1S', '1S_CTRL','1G','QH'].includes(reservationsData.source)"
      :reservations-data="reservationsData"
      :is-booking-out-system="isBookingOutSystem"
      :is-hide-function-button="isHideFunctionButton"
      :can-issue-limit="canIssueLimit"
      :is-locked-modify="isLockedModify"
    />

    <!-- NOTE - DỊCH VỤ THÊM -->
    <!-- <b-card
      v-if="reservationsData && !isEmpty(reservationsData.itineraries)"
      header-bg-variant="blue-gradient py-75"
      no-body
      class="border-info"
    >
      <template #header>
        <h6 class="text-airline font-medium-3 m-0">
          {{ $t('reservation.ancillaries') }}
        </h6>
      </template>

      <AncillaryServicesComponent
        :reservations-data="reservationsData"
        :is-booking-out-system="isBookingOutSystem"
        :is-hide-function-button="isHideFunctionButton"
        :can-issue-limit="canIssueLimit"
        :is-locked-modify="isLockedModify"
      />

      <b-alert
        v-if="reservationsData.source.includes('1S') && reservationsData.status === 'HOLD' && !isEmpty(reservationsData.ancillaryServices.filter(item => item.status && ['HD'].includes(item.status)))"
        variant="warning"
        class="py-75 px-1 mx-lg-3 mx-sm-1 text-center fw-700"
        show
      >
        <span class="text-danger">Vietnam Airlines: Vui lòng thanh toán hành trình trước khi thanh toán dịch vụ</span>
      </b-alert>
    </b-card> -->

    <!-- ANCHOR - DỊCH VỤ MUA THÊM ( BẢO HIỂM, PHÒNG CHỜ ) -->
    <OtherSpecialServices
      :reservations-data="reservationsData"
      :is-booking-out-system="isBookingOutSystem"
      :is-hide-function-button="isHideFunctionButton"
      :can-issue-limit="canIssueLimit"
      :is-locked-modify="isLockedModify"
    />

    <!-- ANCHOR Thông tin giá bán = sellingPriceDetails -->
    <!-- <SellingPriceDetails
      v-if="reservationsData.fareInfoTicket && reservationsData.fareInfoTicket.total"
      :reservations-data="reservationsData"
    /> -->

    <!-- ANCHOR - Thông tin giá trả nhà cung cấp -->
    <!-- <PriceDetailsOld
      :reservations-data="reservationsData"
      :is-booking-out-system="isBookingOutSystem"
      :is-hide-function-button="isHideFunctionButton"
      :can-issue-limit="canIssueLimit"
      @openRecalculatePriceModalHandle="openRecalculatePriceModalHandle"
    /> -->

    <!-- ANCHOR - THÔNG BÁO TỪ HỆ THỐNG -->
    <NotiFromSystem
      v-if="['VN1A_MT', 'VN1A', '1S', '1S_CTRL', '1G', '1A'].includes(reservationsData.source)"
      :reservations-data="reservationsData"
      :is-booking-out-system="isBookingOutSystem"
      :is-hide-function-button="isHideFunctionButton"
      :can-issue-limit="canIssueLimit"
    />

    <!-- ANCHOR - ACTIONS HISTORY || LỊCH SỬ THAO TÁC (AUDIT LOGS) -->
    <AppCollapse
      v-if="reservationsData.auditLogs"
      type="border-info-2 card"
      class="mb-50"
    >
      <AppCollapseItem
        :is-visible="false"
        title
        class-header="card-header bg-blue-gradient p-0 m-50"
        class="border-info-2"
      >
        <template #header>
          <div class="d-flex-center justify-content-start gap-1">
            <b-img
              src="@icons/history-actions.svg"
              class="icon-card"
              width="50px"
            />
            <h6 class="text-airline font-medium-3 m-0 fw-700">
              {{ $t('reservation.historyActions.title.general') }}
            </h6>
          </div>
        </template>

        <b-tabs
          pills
          class="mt-50"
        >
          <b-tab active>
            <template #title>
              <span class="font-weight-bold">{{ $t('reservation.historyActions.title.payment') }}</span>
            </template>

            <!-- ANCHOR Lịch sử thanh toán -->
            <ActionsHistory :history-data="reservationsData.auditLogs.filter(item => (item.isPayment !== null ? item.isPayment : item.newValue))" />
          </b-tab>

          <!-- ANCHOR Tất cả -->
          <b-tab>
            <template #title>
              <span class="font-weight-bold">{{ $t('reservation.historyActions.title.all') }}</span>
            </template>
            <ActionsHistory
              :history-data="reservationsData.auditLogs"
              :source="reservationsData.source"
              :show-modal="true"
            />
          </b-tab>

          <!-- ANCHOR charges -->
          <b-tab
            v-if="reservationsData.source === 'VJ' && reservationsData.charges && !isRoleF3"
            lazy
          >
            <template #title>
              <span class="font-weight-bold">{{ $t('reservation.historyActions.title.charges') }}</span>
            </template>
            <ChargesFromSource :booking-data="reservationsData" />
          </b-tab>

          <!-- ANCHOR audit tu hang (only VJ) -->
          <b-tab
            v-if="reservationsData.source === 'VJ' && !isRoleF3"
            lazy
          >
            <template #title>
              <span class="font-weight-bold">{{ $t('reservation.historyActions.title.fromSource') }}</span>
            </template>
            <ActionsHistoryFromSource
              :source-data="reservationsData.source"
              :booking-code-data="reservationsData.bookingCode"
            />
          </b-tab>
        </b-tabs>
      </AppCollapseItem>
    </AppCollapse>

    <CustomerAndBookerDetails :reservations-data="reservationsData" />

    <div
      v-if="!hideFeature"
      class="payment-div d-flex-between py-1 px-0 px-lg-4"
    >
      <div v-if="!isMobileView" />
      <div class="d-flex-center">
        <div
          v-if="reservationsData && reservationsData.status === 'DRAFT'"
          class="divider-after"
        >
          <!-- ANCHOR - SUBMIT DRAFT -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary
            :title="$t('reservation.submitBookingInfo')"
            variant="primary"
            class="px-50 px-md-2 mx-50 mx-md-1"
            pill
            @click="handleSubmitDraft(reservationsData)"
          >
            <span class="text-nowrap text-truncate">{{ $t('reservation.submitBooking') }}</span>
          </b-button>
        </div>

        <!-- ANCHOR - MẶT VÉ -->
        <b-button
          id="ticket-face-button"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="px-1 px-md-2 mx-50 mx-md-1"
          variant="primary"
          pill
          @click="handleTicketFaceButton"
        >
          <!-- goToTicketFace() -->
          <!-- @click="$root.$emit('bv::show::modal', 'id-modal-reservation-ticket')" -->
          <span class="text-nowrap text-truncate">{{ $t('reservation.exportTicket') }}</span>
        </b-button>
        <b-popover
          :show.sync="showPopoverCombinationTicket"
          target="ticket-face-button"
          triggers="blur"
          boundary="window"
          placement="top"
          class="rounded p-25"
        >
          <div class="d-flex-center flex-column gap-3 p-1">
            <b-button
              variant="primary"
              class="px-2 py-1 border"
              @click.prevent.stop="goToTicketFace(true)"
            >
              <div class="d-flex-center">
                <feather-icon
                  size="25"
                  icon="Minimize2Icon"
                  style="transform: rotate(45deg); width: 25px; height: 25px; border: 1px solid white;"
                  class="mr-50 rounded-circle p-25"
                />
                <span class="text-16px font-weight-bolder">Gộp mặt vé</span>
              </div>
            </b-button>
            <b-button
              variant="warning"
              class="px-2 py-1 border"
              @click.prevent.stop="goToTicketFace(false)"
            >
              <div class="d-flex-center">
                <feather-icon
                  size="25"
                  icon="Maximize2Icon"
                  style="transform: rotate(45deg); width: 25px; height: 25px; border: 1px solid white;"
                  class="mr-50 rounded-circle p-25"
                />
                <span class="text-16px font-weight-bolder">Tách mặt vé</span>
              </div>
            </b-button>
          </div>
        </b-popover>

        <!-- ANCHOR - TIN NHẮN -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="px-1 px-md-2 mx-50 mx-md-1"
          variant="primary"
          pill
          @click="$root.$emit('bv::show::modal', 'id-modal-reservation-sms')"
        >
          <span class="text-nowrap text-truncate">{{ $t('reservation.exportSms') }}</span>
        </b-button>

        <!-- ANCHOR - SEND SUBMIT BOOKING MAIL -->
        <b-button
          v-if="reservationsData && reservationsData.status === 'HOLD' && reservationsData.isDraft"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.v-primary
          :title="$t('reservation.sendSubmitBookingMail')"
          variant="primary"
          class="px-50 px-md-2 mx-50 mx-md-1"
          pill
          @click="handleSendSubmitBookingMail(reservationsData)"
        >
          <span class="text-nowrap text-truncate">{{ $t('reservation.sendSubmitBookingMail') }}</span>
        </b-button>
      </div>
      <div>
        <!-- ANCHOR - THANH TOÁN -->
        <b-button
          v-if="reservationsData && reservationsData.status !== 'DRAFT'"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.v-primary
          :title="$t('reservation.payment')"
          class="px-50 px-md-2 mx-50 mx-md-1"
          variant="danger"
          pill
          :disabled="isDisabledPaymentFlight(reservationsData) || !canAccess('booking.issueTicket') || isBookingOutSystem || !canIssueLimit || isLockedModify"
          @click="openPaymentFlightModalHandle(reservationsData)"
        >
          <span class="text-nowrap text-truncate">{{ $t('reservation.payment') }}</span>
        </b-button>
      </div>
    </div>

    <!-- ANCHOR: MODAL -->
    <ModalRecalculatePrice :booking-data="reservationsData" />

    <ModalRecalculateVN1A :booking-data="reservationsData" />

    <ModalEditContact :data-reservation="reservationsData" />

    <ModalCancelFlight
      :is-check-price="isCheckPrice"
      :action-cancel-trip="actionCancelTrip"
      :segment-id-to-disabled="segmentIdToDisabled"
    />

    <ModalCancelFlightVN1A
      v-if="['VN1A', 'VN1A_MT'].includes(reservationsData.source)"
      :source="reservationsData.source"
      :pnr-number="reservationsData.bookingCode"
      :pax-code="reservationsData.paxContact?.code"
      :agency-code="reservationsData.agency"
    />

    <ModalChangeFlight v-if="reservationsData" />
    <!-- <ModalAddFlight /> -->
    <!-- <ModalQuickAddFlight /> -->

    <ModalPaymentFlight
      v-if="reservationsData"
      :booking-data="reservationsData"
    />

    <ModalRemarks :booking-data="reservationsData" />
    <ModalOSIFlight :booking-data="reservationsData" />

    <ModalSendQueues :booking-data="reservationsData" />

    <ModalSpecialServices :booking-data="reservationsData" />

    <ModalConfirmSegments :booking-data="reservationsData" />

    <ModalSubmitDraft
      :booking-data="reservationsData"
      @refetch-booking-data="refetchBookingData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BCol,
  BRow,
  BBadge,
  BButton,
  BTableLite,
  BButtonGroup,
  VBModal,
  BAlert,
  BPopover,
  VBTooltip,
  BTabs, BTab,
} from 'bootstrap-vue'
import { ref, toRefs, computed } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'

import store from '@/store'
import { resolveBookingStatusVariant } from '@/constants/selectOptions'
import router from '@/router'

import {
  dateTime,
  convertISODateTime,
  toHoursAndMinutes,
  formatCurrency,
  resolveAirlineFlightNumber,
} from '@core/utils/filter'

import useReservationHandle, { resolveAirPnr } from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BCol,
    BImg,
    BRow,
    BButton,
    BTableLite,
    BButtonGroup,
    BAlert,
    BBadge,
    BPopover,
    BTabs,
    BTab,

    // Components
    // PriceDetailsOld: () => import('@reservation/reservation-modify/components/detail/components/PriceDetails.vue'),
    // SellingPriceDetails: () => import('@reservation/reservation-modify/components/detail/components/SellingPriceDetails.vue'),
    PriceDetails: () => import('@reservation/reservation-modify/components/detail/price-details/PriceDetails.vue'),
    NotiFromSystem: () => import('@reservation/reservation-modify/components/detail/components/NotiFromSystem.vue'),
    CustomerAndBookerDetails: () => import('@reservation/reservation-modify/components/detail/components/CustomerAndBookerDetails.vue'),
    PassengerDetails: () => import('@reservation/reservation-modify/components/detail/passenger-details/index.vue'),
    ETicketDetails: () => import('@reservation/reservation-modify/components/detail/eticket-details/index.vue'),
    FlightInfoCard: () => import('@reservation/reservation-modify/components/detail/components/FlightInfoCard.vue'),
    CancelFlight: () => import('@reservation/reservation-modify/components/detail/components/CancelFlight.vue'),

    // Modal
    ModalCancelFlight: () => import('@/views/apps/reservation/reservation-modify/components/detail/flights-details/ModalCancelFlight.vue'),
    ModalCancelFlightVN1A: () => import('@/views/apps/reservation/reservation-modify/components/detail/flights-details/ModalCancelFlightVN1A.vue'),
    ModalChangeFlight: () => import('@reservation/reservation-modify/components/detail/flights-details/change-flights/index.vue'),
    ModalEditContact: () => import('@reservation/reservation-modify/components/detail/contact-info/index.vue'),

    // ModalAddFlight: () => import('@reservation/reservation-modify/components/detail/add-flights/result/index.vue'),
    // ModalQuickAddFlight: () => import('@reservation/reservation-modify/components/detail/ModalQuickAddFlight.vue'),
    ModalSubmitDraft: () => import('@reservation/reservation-modify/components/detail/ModalSubmitDraft.vue'),

    ModalRecalculatePrice: () => import('@reservation/reservation-modify/components/detail/recalculate-price/ModalRecalculatePrice.vue'),
    ModalRecalculateVN1A: () => import('@reservation/reservation-modify/components/detail/recalculate-price/ModalRecalculateVN1A.vue'),
    ModalPaymentFlight: () => import('@reservation/reservation-modify/components/detail/ModalPaymentFlight.vue'),
    AncillaryServicesComponent: () => import('@reservation/reservation-modify/components/detail/ancillary-services/index.vue'),
    OtherSpecialServices: () => import('@reservation/reservation-modify/components/detail/special-services/index.vue'),
    ActionsHistory: () => import('@reservation/reservation-modify/components/detail/actions-history/index.vue'),
    ActionsHistoryFromSource: () => import('@reservation/reservation-modify/components/detail/actions-history/ActionsHistoryFromSource.vue'),
    ChargesFromSource: () => import('@reservation/reservation-modify/components/detail/actions-history/ChargesFromSource.vue'),
    ModalRemarks: () => import('@reservation/reservation-modify/components/detail/ModalRemarks.vue'),
    ModalOSIFlight: () => import('@reservation/reservation-modify/components/detail/ModalOSIFlight.vue'),

    ModalSendQueues: () => import('@reservation/reservation-modify/components/detail/ModalSendQueues.vue'),
    ModalSpecialServices: () => import('@reservation/reservation-modify/components/detail/ModalSpecialServices.vue'),
    ModalConfirmSegments: () => import('@reservation/reservation-modify/components/detail/ModalConfirmSegments.vue'),

    IAmCollapseAlert: () => import('@/components/IAmCollapseAlert.vue'),
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),

    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    reservationsData: {
      type: Object,
      default: () => { },
    },
    shortenBookingText: {
      type: String,
      default: () => '',
    },
    isBookingOutSystem: {
      type: Boolean,
      default: false,
    },
    isHideFunctionButton: {
      type: Boolean,
      default: false,
    },
    canIssueLimit: {
      type: Boolean,
      default: false,
    },
    isLockedModify: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root }) {
    const {
      confirmSegments,
      confirmFlightsSegments,
      addArunksToItineraries,
      getFareRulesAirlineByBookingClass,
      getAirlineByOperating,
      getBaggageInBookingDetail,
      stopPaymentReminder,
      cancelFlight1G,
      refetchBookingData,
      submitBooking,
      sendSubmitBookingMail,
      hideFeature,
      NDC_1A_BOOKING,
      delay,
      copyPnr,
      disabledCopy,
    } = useReservationHandle()

    const { toastError, toastWarning } = useToast()

    const passengerData = ref({})
    const showActionsHistory = ref(false)
    const reservationsData = toRefs(props).reservationsData
    const isProduction = computed(() => process.env.VUE_APP_ENV === 'production')

    const meData = computed(() => store.getters['userStore/getMeData'])
    const isBookerF1 = computed(() => {
      if (isEmpty(meData.value)) return false
      return meData.value?.agency?.id === 1000000 && meData.value?.type === 'BE'
    })

    // const isAdminF1 = computed(() => {
    //   if (isEmpty(meData.value)) return false
    //   return meData.value?.agency?.id === 1000000 && ['ADM', 'OPE'].includes(meData.value?.type)
    // })

    const lastDateToPurchase = computed(() => {
      const { source, priceQuotes, historicalPriceQuotes } = reservationsData.value
      if (!['1G', '1S', '1S_CTRL', 'VN1A', 'VN1A_MT'].includes(source)) return null
      let result = null
      if (priceQuotes?.length) {
        let minLastDateToPurchase = null
        priceQuotes.forEach(priceQuotesItem => {
          if (!minLastDateToPurchase || priceQuotesItem.lastDateToPurchase < minLastDateToPurchase) {
            minLastDateToPurchase = priceQuotesItem.lastDateToPurchase
          }
        })
        result = minLastDateToPurchase
      } else if (historicalPriceQuotes?.length) {
        result = historicalPriceQuotes[0].lastDateToPurchase
      }
      return convertISODateTime(result).dateTime || null
    })

    const expirationTime = computed(() => {
      const { source, priceQuotes, historicalPriceQuotes } = reservationsData.value
      if (!['VN1A', 'VN1A_MT'].includes(source)) return null
      let result = null
      if (priceQuotes?.length) {
        let minExpirationTime = null
        priceQuotes.forEach(priceQuotesItem => {
          if (!minExpirationTime || priceQuotesItem.expirationTime < minExpirationTime) {
            minExpirationTime = priceQuotesItem.expirationTime
          }
        })
        result = minExpirationTime
      } else if (historicalPriceQuotes?.length) {
        result = historicalPriceQuotes[0].expirationTime
      }
      return convertISODateTime(result).dateTime || null
    })

    function getAirCraftByIata(code) {
      return store.getters['globalConfig/getAirCraftByIata'](code)
    }

    function openRecalculatePriceModalHandle(source) {
      if (['VN1A', 'VN1A_MT'].includes(source)) {
        this.$bvModal.show('modal-recalculate-price-VN1A')
      } else {
        this.$bvModal.show('modal-recalculate-price')
      }
    }

    async function openChangeFlightHandle(booking) {
      const isBookingHold = ['VN1A_MT', 'VN1A', 'QH'].includes(booking.source) && (booking.status === 'HOLD')
      if (isBookingHold) {
        toastWarning({ title: 'Mã đặt chỗ chưa thanh toán.' })
        return
      }

      const isEmptyTrip = isEmpty(booking.itineraries)
      if (isEmptyTrip) {
        toastWarning({ title: 'Không có hành trình hợp lệ.' })
        return
      }

      const isBookingHasRefundAmountVJ = ['VJ'].includes(booking.source) && (booking?.refundAmountIdentification > 0)
      if (isBookingHasRefundAmountVJ) {
        toastError({
          title: 'Mã đặt chỗ còn tiền bảo lưu định danh',
          content: 'Không hỗ trợ thao tác này, vui lòng gọi tổng đài hoặc liên hệ team Sales!',
        })
        return
      }

      const isEmptyAvailEticketToChange = ['VN1A_MT', 'VN1A'].includes(booking.source) && isEmpty(booking.paxLists.filter(p => !isEmpty(p.eticket)).map(p => p.eticket).flat().filter(e => ['TKT'].includes(e.ticketType) && ['OPEN'].includes(e.status)))

      if (isEmptyAvailEticketToChange) {
        toastWarning({ title: 'Không có số vé hợp lệ.' })
        return
      }

      const isEmptyAdultEticket = ['VN1A_MT', 'VN1A'].includes(booking.source) && isEmpty(booking.paxLists.filter(p => ['ADULT'].includes(p.paxType) && !isEmpty(p.eticket)).map(p => p.eticket).flat().filter(e => ['TKT'].includes(e.ticketType) && ['OPEN'].includes(e.status)))
      if (isEmptyAdultEticket) {
        toastWarning({ title: 'Không có số vé người lớn hợp lệ.' })
        return
      }

      store.dispatch('app-reservation/setIsAddFlights', false)
      await delay(200)

      this.$bvModal.show('modal-modify-change-flight-select-trip')
    }

    function openRemarksModalHandle() {
      this.$bvModal.show('modal-modify-remarks')
    }
    function openOSIFlightModalHandle() {
      this.$bvModal.show('modal-osi-flight')
    }
    function openSendQueuesModalHandle() {
      this.$bvModal.show('modal-send-queues')
    }

    function openSpecialServicesModalHandle() {
      this.$bvModal.show('modal-special-services')
    }

    function handleConfirmSC(trips) {
      if (isEmpty(trips)) {
        toastError({ title: 'Không có hành trình trong mã đặt chỗ !' })
        return
      }

      this.$bvModal
        .msgBoxConfirm(this.$t('reservation.confirmSegments.confirm'), {
          title: this.$t('reservation.confirmSegments.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            if (['QH', 'VN1A'].includes(reservationsData.value.source)) {
              confirmFlightsSegments({
                pnrNumber: reservationsData.value.bookingCode,
                source: reservationsData.value.source,
                agencyCode: reservationsData.value.agency,
                paxCode: reservationsData.value.paxContact?.code ?? reservationsData.value?.paxContact,
              })
                .then(() => {
                  const titleMsg = this.$createElement('div', { domProps: { innerHTML: `<span class='font-weight-bolder'>${this.$t('notification.confirmSCNote')}</span>` } })
                  this.$bvModal
                    .msgBoxOk([titleMsg], {
                      title: this.$t('notification.title'),
                      size: 'sm',
                      okVariant: 'primary',
                      okTitle: this.$t('modal.confirm'),
                      centered: true,
                    })
                })
                .finally(() => {
                  this.$bvModal.hide('modal-api-loading')
                })
            } else {
              confirmSegments({
                pnrNumber: reservationsData.value.bookingCode,
                source: reservationsData.value.source,
              // agencyCode: reservationsData.value.agency,
              // paxCode: reservationsData.value.paxContact?.code ?? reservationsData.value?.paxContact,
              })
                .finally(() => {
                  this.$bvModal.hide('modal-api-loading')
                })
            }
          }
        })
    }

    // function confirmSegmentsSelectedHandle() {
    //   this.$bvModal
    //     .msgBoxConfirm(this.$t('reservation.confirmSegments.confirm'), {
    //       title: this.$t('reservation.confirmSegments.title'),
    //       size: 'sm',
    //       okVariant: 'primary',
    //       okTitle: this.$t('modal.yes'),
    //       cancelTitle: this.$t('modal.no'),
    //       cancelVariant: 'outline-secondary',
    //       hideHeaderClose: true,
    //       centered: true,
    //     })
    //     .then(value => {
    //       if (value) {
    //         this.$bvModal.show('modal-api-loading')
    //         confirmSegments({
    //           pnrNumber: reservationsData.value.bookingCode,
    //           source: reservationsData.value.source,
    //           agencyCode: reservationsData.value.agency,
    //           paxCode: reservationsData.value.paxContact?.code ?? reservationsData.value?.paxContact,
    //         })
    //           .finally(() => {
    //             this.$bvModal.hide('modal-api-loading')
    //           })
    //       }
    //     })
    // }

    async function openAddFlightModalHandle() {
      await new Promise(resolve => {
        store.dispatch('app-reservation/setIsAddFlights', true)
        resolve()
      })
      await delay(200)
      this.$bvModal.show('modal-modify-flight-search-trip')
    }

    // function openQuickAddFlightModalHandle() {
    //   this.$bvModal.show('modal-modify-quick-add-flight')
    // }

    function openEditContactModalHandle() {
      if ((props.reservationsData && ['VU'].includes(props.reservationsData.source))) {
        toastError({ title: 'Không hỗ trợ thay đổi thông tin liên hệ đối với vé hãng Vietravel Airlines.' })
        return
      }
      this.$bvModal.show('modal-modify-contact-info')
    }

    function openLinkedBookingHandle(id) {
      router
        .push({ name: 'apps-reservations-modify', params: { id } })
        .catch(() => { })
    }

    const resolveAirlineImg = data => {
      if (data.airlines) return data.airlines.split(' | ')[0]
      if (data.source.includes('1S')) return 'VN'
      if (data.source.includes('VZ')) return 'VJ'
      return data.source
    }

    function openCrossReferenceHandle(reservationsData, crossReference) {
      const source = reservationsData.source
      const id = crossReference.id || `${source}-${crossReference.pnrNumber}`
      router
        .push({ name: 'apps-reservations-modify', params: { id } })
        .catch(() => { })
    }

    function openPaymentFlightModalHandle(bookingData) {
      if (!isBookerF1.value) {
        const hasHLSegment = bookingData.source.includes('1S') && bookingData.itineraries.some(trip => trip.some(segment => ['HL'].includes(segment.status) || ['HL'].includes(segment.bookingStatus)))
        if (hasHLSegment) {
          toastError({
            title: 'reservation[\'Tickets are not issued for HL itineraries, agents please check the itinerary information or contact the booker to issue tickets\']',
          })
          return
        }
      }

      if (['1A'].includes(bookingData.source) && !NDC_1A_BOOKING.value) {
        Vue.swal({
          title: this.$t('Notification'),
          text: this.$t('reservation[\'For 1A GDS bookings, please contact the booker to issue tickets\']'),
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
        })
        return
      }

      this.$bvModal.show('modal-modify-payment-flight')
    }

    function handleSubmitDraft(reservationsData) {
      if (!reservationsData.roundtripBooking || ([reservationsData].concat(reservationsData.roundtripBooking).filter(item => !!item).some(booking => booking.status !== 'DRAFT'))) {
        const contentMsg = () => this.$createElement('div', { domProps: { innerHTML: `Bạn có chắc chắn muốn thực hiện đặt chỗ <code>${reservationsData.bookingCode}</code> không?` } })
        const titleMsg = () => this.$createElement('h4', { domProps: { innerHTML: root.$t('reservation.submitBooking') } })
        root.$bvModal
          .msgBoxConfirm(contentMsg(), {
            title: titleMsg(),
            size: 'sm',
            okVariant: 'danger',
            okTitle: root.$t('reservation.confirm'),
            cancelTitle: root.$t('modal.no'),
            cancelVariant: 'flat-dark',
            centered: true,
          })
          .then(async value => {
            if (value) {
              root.$bvModal.show('modal-api-loading')
              await submitBooking(reservationsData.id, {
                includeRTB: false,
              }).then(() => {
                refetchBookingData()
              })
              root.$bvModal.hide('modal-api-loading')
            }
          })
      } else {
        this.$bvModal.show('modal-submit-draft')
      }
    }

    function handleSendSubmitBookingMail(reservationsData) {
      const contentMsg = () => this.$createElement('div', { domProps: { innerHTML: 'Bạn có chắc chắn muốn thực hiện không?' } })
      const titleMsg = () => this.$createElement('h4', { domProps: { innerHTML: root.$t('reservation.sendSubmitBookingMail') } })
      root.$bvModal
        .msgBoxConfirm(contentMsg(), {
          title: titleMsg(),
          size: 'sm',
          okVariant: 'danger',
          okTitle: root.$t('reservation.confirm'),
          cancelTitle: root.$t('modal.no'),
          cancelVariant: 'flat-dark',
          centered: true,
        })
        .then(async value => {
          if (value) {
            root.$bvModal.show('modal-api-loading')
            await sendSubmitBookingMail(reservationsData.id)
            root.$bvModal.hide('modal-api-loading')
          }
        })
    }

    // ANCHOR - PAYMENT
    function isDisabledPaymentFlight(bookingData) {
      const result = false

      if (
        bookingData.source === 'VJ'
        && (bookingData.bookingStatus?.toUpperCase() !== 'ACTIVE' || (bookingData.totalAmountToBePaid <= 0 && bookingData.pricingInfo.total <= 0))
      ) {
        return true
      }

      if (
        !['VJ', '1S', '1S_CTRL', '1G'].includes(bookingData.source)
        && bookingData.bookingStatus?.toUpperCase() === 'ACTIVE'
        && bookingData.totalAmountToBePaid <= 0
      ) {
        return true
      }

      if (!bookingData.itineraries.length || bookingData.totalAmountToBePaid <= 0) {
        return true
      }

      return result
    }

    const segmentIdToDisabled = ref([])

    function getSegmentIdNotPayTripVJ(bookingData) {
      // Array all segmentId
      const allSegmentIdArray = bookingData.itineraries.flat().map(item => item.segmentId)

      // Get list segmentId PAID in paxLists[0].eticket
      // LINH CHECK LAI CASE NAY
      const segmentIdHadPaidArray = bookingData.paxLists[0].eticket.filter(e => ['TKT', 'EMD'].includes(e.ticketType)).map(item => item.segmentId.split(' | ')).flat()

      // result === [] => All paid trips => Disable cancel button
      const segmentNotPaidArr = allSegmentIdArray.filter(item => !segmentIdHadPaidArray.includes(item))

      return segmentNotPaidArr // list segmentId not pay
    }

    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    const isVoidAllFlight = bkData => {
      const allEticket = bkData.paxLists.flatMap(p => p.eticket).filter(Boolean)
      const result = !allEticket.some(e => ['TKT'].includes(e.ticketType) && ['OPEN'].includes(e.status))
      // console.log({ allEticket, result })
      return result
    }

    function disableCancelFlight(bookingData) {
      // VN1A cho huỷ hết
      if (['VN1A_MT', 'VN1A'].includes(bookingData.source)) {
        return false
      }

      if (!bookingData.itineraries.length) {
        return true
      }

      // if (['VJ', 'VZ'].includes(bookingData.source) && ['HOLD'].includes(bookingData.status) && isRoleF3.value) {
      //   return true
      // }

      if (['VN1A_MT', 'VN1A', 'VU', 'QH', 'VJ', '1B'].includes(bookingData.source) && bookingData.status.toUpperCase().includes('PAID')) {
        if (['VJ'].includes(bookingData.source)) {
          if (isEmpty(getSegmentIdNotPayTripVJ(bookingData))) return true
          return false
        }
        if (['VN1A_MT', 'VN1A'].includes(bookingData.source) && isVoidAllFlight(bookingData)) return false
        return true
      }

      if (['1G'].includes(bookingData.source) && bookingData.status.toUpperCase() !== 'HOLD') {
        return true
      }

      if (['1A'].includes(bookingData.source)) {
        if (bookingData.supplierPNRs && !isEmpty(bookingData.supplierPNRs) && bookingData.supplierPNRs.every(c => !!c.supplierCode)) {
          return !bookingData.supplierPNRs.some(c => c.supplierCode.includes('GDS'))
        }
      }
      return false
    }

    function disableChangeFlight(bookingData) {
      const disableSource = isProduction.value ? ['QH'] : []
      if (!bookingData.itineraries.length || disableSource.includes(bookingData.source)) {
        return true
      }

      return false
    }

    function disableRefundFlight(bookingData) {
      const availSource = isProduction.value ? ['1G', '1S_CTRL', '1S', 'QH'] : ['1G', '1S_CTRL', '1S']
      if (!bookingData.itineraries.length || availSource.includes(bookingData.source)) {
        return true
      }

      if (['VU', 'QH', 'VJ'].includes(bookingData.source) && bookingData.status === 'HOLD') {
        return true
      }

      // CHECK điều kiện không được hoàn VJ: case tách hành khách VJ đang disabled refund button => tạm comment
      // if (bookingData.source === 'VJ' && !isEmpty(getSegmentIdNotPayTripVJ(bookingData))) {
      //   // Array all segmentId
      //   const allSegmentIdArray = bookingData.itineraries.flat().map(item => item.segmentId)

      //   if (getSegmentIdNotPayTripVJ(bookingData).length === allSegmentIdArray.length) {
      //     return true
      //   }
      // }

      return false
    }

    const actionCancelTrip = ref('CANCEL')
    const isCheckPrice = ref(false)

    function checkOverTime(bookingData) {
      // CHECK điều kiện không được hoàn vé:
      // QH: ECONOMYSAVERMAX, ECONOMYSAVER === 'KHÔNG ĐƯỢC HOÀN' => QH gửi tất cả itineraries
      // VU: NON-REFUNDABLE === 'KHÔNG ĐƯỢC HOÀN'

      // VJ: Eco, Deluxe (thời gian < 24h so với giờ bay) === 'KHÔNG ĐƯỢC HOÀN'
      // VJ: Skyboss, Skyboss Business (thời gian < 3h so với giờ bay) === 'KHÔNG ĐƯỢC HOÀN'
      // VU: REFUNDABLE(thời gian < 3h so với giờ bay) === 'KHÔNG ĐƯỢC HOÀN'

      // const departureTimeObj = new Date(convertISODateTime(bookingData.itineraries[0][0].departure.at, bookingData.itineraries[0][0].departure.timezone).ISOdatetime)
      // const checkTime = departureTimeObj.getTime() - currentTimeObj.getTime() < (4 * 60 * 60 * 1000)// 4 tiếng

      if (bookingData.source === 'QH') {
        const isNotRefundClass = bookingData.itineraries.some(trip => trip.some(seg => ['ECONOMYSAVER', 'ECONOMYSAVERMAX'].includes(seg.groupClass)))

        if (isNotRefundClass) {
          toastError({ title: 'Hạng vé không được hoàn.' })
          return true
        }
      } else if (bookingData.source === 'VU') {
        const isNotRefundClass = bookingData.itineraries.every(trip => trip.every(seg => ['A'].includes(seg.bookingClass)))

        if (isNotRefundClass) {
          toastError({ title: 'Hạng vé không được hoàn.' })
          return true
        }
      }

      return false
    }

    function cancelFlight1GConfirm(payload) {
      const titleMsg = () => root.$createElement('div', {
        domProps: {
          innerHTML: `<div>${root.$t('reservation.confirmCancelFlight')}</div>
      <div
        class="px-1 py-1 mt-50 bg-light-danger text-white"
      >Hủy chuyến bay sẽ hủy hẹn giờ xuất vé (nếu có)</div>`,
        },
      })
      root.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: root.$t('reservation.cancelFlight'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: root.$t('reservation.cancelFlight'),
          cancelTitle: root.$t('modal.no'),
          cancelVariant: 'flat-dark',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            root.$bvModal.show('modal-api-loading')
            cancelFlight1G(payload).finally(() => {
              root.$bvModal.hide('modal-api-loading')
            })
          }
        })
    }

    async function openCancelFlightModalHandle(bookingData, action = 'CANCEL') {
      // VN1A modal huỷ riêng
      if (['VN1A', 'VN1A_MT'].includes(bookingData.source) && action === 'CANCEL') {
        if (!bookingData.itineraries.length) {
          toastError({ title: 'Không có hành trình để huỷ!' })
          return
        }
        this.$bvModal.show('modal-cancel-flight-vn1a')
        return
      }

      if (bookingData.source === '1G' && action === 'CANCEL' && bookingData.status === 'HOLD' && bookingData.itineraries.length) {
        cancelFlight1GConfirm({ source: bookingData.source, pnrNumber: bookingData.bookingCode, contentSource: bookingData.extra.find(i => i.contentSource).contentSource })
        return
      }

      if (action === 'REFUND') {
        const hasOverTime = checkOverTime(bookingData)
        if (hasOverTime) {
          return
        }
        if (['VU'].includes(bookingData.source)) {
          toastError({ title: 'Vui lòng liên hệ Booker để được hỗ trợ hoàn vé Vietravel Airlines.' })
          return
        }
      }

      actionCancelTrip.value = 'CANCEL'
      isCheckPrice.value = false

      segmentIdToDisabled.value = getSegmentIdNotPayTripVJ(bookingData)

      await new Promise(resolve => {
        if (action === 'REFUND') {
          actionCancelTrip.value = 'REFUND'
          if (['VJ', 'QH'].includes(bookingData.source)) {
            isCheckPrice.value = true
          }
        }

        if (action === 'CANCEL' && ['VJ'].includes(bookingData.source)) {
          isCheckPrice.value = true
        }
        resolve()
      })

      this.$bvModal.show('modal-cancel-flight')
    }

    async function openSegmentsHandleModal() {
      await this.$bvModal.show('modal-modify-confirm-segments')
    }

    const indexFlightWithArunks = ref(null)

    // function addArunksToItineraries(bookingData) {
    //   const itinerariesWithArunks = [...bookingData.itineraries, ...bookingData.arunks]
    //   // eslint-disable-next-line func-names
    //   const result = sortBy(itinerariesWithArunks, [function (item) {
    //     if (isArray(item)) {
    //       return item[0].segmentId
    //     }
    //     return item.segmentId
    //   }])
    //   // const indexArray = []
    //   // let count = 1
    //   // result.forEach(item => {
    //   //   if (isArray(item)) {
    //   //     indexArray.push(count)
    //   //     count += 1
    //   //   } else {
    //   //     indexArray.push(0)
    //   //   }
    //   // })
    //   // indexFlightWithArunks.value = indexArray
    //   return result
    // }

    function goToTicketFace(combinate = false) {
      if (combinate) {
        if (['CANCEL'].includes(reservationsData.value?.status)) {
          toastError({
            title: 'reservation[\'bookingCancelled\']',
            content: 'reservation[\'Cannot view tickets for inactive bookings\']',
          })
          return
        }

        const hasActiveTrip = ['HOLD', 'PAID', 'PARTIALLY_PAID'].includes(reservationsData.value?.roundtripBooking?.status) || !isEmpty(reservationsData.value?.multiCityBookings?.filter(booking => !['CANCEL'].includes(booking?.status)))
        if (!hasActiveTrip) {
          toastError({
            title: 'reservation[\'Tickets cannot be combined\']',
            content: 'reservation[\'No active ticket links found\']',
          })
          return
        }
      }

      const { id, source, bookingCode } = reservationsData.value
      const isPastDayBooking = router.currentRoute.name === 'apps-past-day-booking-modify'
      const query = {}
      if (combinate) query.combinate = true
      if (isPastDayBooking) query.isPast = true
      const route = router.resolve({
        name: 'apps-reservations-ticket',
        params: { id: `${id || `${source}-${bookingCode}`}` },
        query,
      })
      window.open(route.href)
    }

    const showPopoverCombinationTicket = ref(false)
    const isVisibleCombinationTicket = computed(() => reservationsData.value?.roundtripBooking || reservationsData.value?.multiCityBookings?.length)
    function handleTicketFaceButton() {
      if (isVisibleCombinationTicket.value) {
        showPopoverCombinationTicket.value = !showPopoverCombinationTicket.value
      } else {
        goToTicketFace()
      }
    }

    const isShowTotalPricePaid = ref(true)

    function handleTogglePaymentReminder(bookingData) {
      const titleVNode = this.$createElement('div', {
        domProps: {
          innerHTML: `
          <div class="mb-2 font-weight-bolder">
            ${this.$t('reservation.status')}: <span class="${bookingData.isPaymentReminder ? 'text-success' : 'text-danger'}">Đang ${bookingData.isPaymentReminder ? 'bật' : 'tắt'} <span>
          </div>
        <div class="font-italic">
          <span>${this.$t('reservation.paymentReminder.confirm', { statusChange: bookingData.isPaymentReminder ? 'TẮT' : 'BẬT' })}</span>
        </div>
        `,
        },
      })

      this.$bvModal
        .msgBoxConfirm(titleVNode, {
          title: this.$t('reservation.paymentReminder.title'),
          size: 'sm',
          okVariant: bookingData.isPaymentReminder ? 'danger' : 'success',
          okTitle: bookingData.isPaymentReminder ? this.$t('modal.deactivate') : this.$t('modal.activate'),
          cancelTitle: this.$t('modal.back'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')

            stopPaymentReminder(bookingData)
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    const cancelFlightList = computed(() => store.getters['app-reservation/getCancelFlight'])

    const isShowContactInfo = computed(() => !isRoleF3.value || (meData?.value?.agency?.sendMailAirline && meData?.value?.agency?.sendPhoneAirline))
    return {
      NDC_1A_BOOKING,
      cancelFlightList,
      passengerData,
      isEmpty,
      isProduction,
      showActionsHistory,

      dateTime,
      convertISODateTime,
      toHoursAndMinutes,
      formatCurrency,
      getAirCraftByIata,
      openCancelFlightModalHandle,
      openRecalculatePriceModalHandle,
      openChangeFlightHandle,
      resolveBookingStatusVariant,
      openAddFlightModalHandle,
      // openQuickAddFlightModalHandle,
      openLinkedBookingHandle,
      openCrossReferenceHandle,
      openEditContactModalHandle,
      openPaymentFlightModalHandle,
      openRemarksModalHandle,
      openOSIFlightModalHandle,
      openSendQueuesModalHandle,
      openSpecialServicesModalHandle,
      handleConfirmSC,
      openSegmentsHandleModal,

      isDisabledPaymentFlight,
      actionCancelTrip,
      isCheckPrice,
      disableCancelFlight,
      disableRefundFlight,
      disableChangeFlight,
      segmentIdToDisabled,

      addArunksToItineraries,
      indexFlightWithArunks,
      getFareRulesAirlineByBookingClass,
      getAirlineByOperating,
      getBaggageInBookingDetail,
      goToTicketFace,
      showPopoverCombinationTicket,
      handleTicketFaceButton,

      lastDateToPurchase,
      expirationTime,

      isShowTotalPricePaid,
      resolveAirlineImg,
      resolveAirPnr,

      handleTogglePaymentReminder,
      isRoleF3,

      handleSubmitDraft,
      handleSendSubmitBookingMail,
      refetchBookingData,
      hideFeature,
      resolveAirlineFlightNumber,
      isShowContactInfo,
      copyPnr,
      disabledCopy,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.payment-div {
  position: sticky;
  bottom: 0;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  border-radius: 8px;
}

.min-h-unset {
  min-height: unset;
}

.rotate-180 {
  transition: all 0.25s ease-out;
  transform: rotate(-180deg);
}

.sticky-total-price {
  display: block;
  position: relative;
  z-index: 2;
  // left: -8%;
  // top: 88%;
}

.divider-after {
  display: flex;
  &::after {
    content: "";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #d3d3d3;
    height: 30px;
    margin: auto 1rem;

    // @media (max-width: 768px) {
    //  content: none;
    // }
  }
}
</style>
